import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

// Components
import { Layout } from "../components/layout"
import { AthleteWrapper } from "../components/athlete"
import { H1, Paragraph } from "../components/typography"
import { PortableText } from "../components/content"
// import { SEO } from "../components/meta"

const Content = styled.section`
  grid-column: 2 / span 2;
  padding: 2.5rem;

  @media (max-width: 1000px) {
    grid-column: span 1;
    padding: 1rem 0;
  }
`

const Athlete = ({ data }) => {
  const doc = data.sanityAthlete
  if (!doc) return null
  if (!doc.image) return null

  return (
    <Layout>
      <AthleteWrapper>
        {doc.image.asset.fluid && (
          <Img fluid={doc.image.asset.fluid} alt={doc.image.caption} />
        )}
        <Content>
          <H1>{doc.name}</H1>
          {doc.yearsActive && (
            <Paragraph emphasis>
              SPUG from {doc.yearsActive.startYear} – {doc.yearsActive.endYear}
            </Paragraph>
          )}
          <Paragraph>**Athlete biographies coming soon!**</Paragraph>
          {doc._rawBody && <PortableText blocks={doc._rawBody} />}
        </Content>
      </AthleteWrapper>
    </Layout>
  )
}

export const query = graphql`
  query AthleteQuery($id: String!) {
    sanityAthlete(id: { eq: $id }) {
      id
      name
      image {
        caption
        asset {
          url
          fluid(maxWidth: 400, maxHeight: 500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      yearsActive {
        startYear
        endYear
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
      slug {
        current
      }
    }
  }
`

export default Athlete
