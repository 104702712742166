import styled from "styled-components"

const AthleteWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1200px;
  margin: 0 auto;
  padding: 2.5rem 1rem;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`

export default AthleteWrapper
